import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { HeaderComponent } from "../header";
import { HomeComponent } from "../home";
import { FooterComponent } from "../footer";

const LayoutComponent = () => {
  return (
    <div id="page" className="page">
      <Router>
        <HeaderComponent />
        <Switch>
          <Route path="/" exact>
            <HomeComponent />
          </Route>
        </Switch>
        <FooterComponent />
      </Router>
    </div>
  );
};

export default LayoutComponent;
