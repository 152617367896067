import { Link } from "react-router-dom";

const DesktopLogo = () => {
  return (
    <>
      <div className="desktoplogo">
        <Link to="/" className="logo-black">
          <img src="/assets/images/regular-logo-dark.png" alt="header-logo" />
        </Link>
      </div>
      <div className="desktoplogo">
        <Link to="/" className="logo-white">
          <img src="/assets/images/retina-logo-1.png" alt="header-logo" />
        </Link>
      </div>
    </>
  );
};

export default DesktopLogo;
