const ClientsContent = () => {
  return (
    <div id="brands-2" className="bg-lightgrey wide-70 brands-section division">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="brands-title text-center">
              <p className="p-xl">
                Supporting the growth of individuals and businesses
              </p>
            </div>
          </div>
        </div>
        <div className="brands-2-wrapper">
          <div className="row justify-content-md-center row-cols-2 row-cols-sm-3 row-cols-md-5">
            <div className="col">
              <div className="brand-logo">
                <a href="#">
                  <img
                    className="img-fluid"
                    src="/assets/images/brand-1.png"
                    alt="brand-logo"
                  />
                </a>
              </div>
            </div>
            <div className="col">
              <div className="brand-logo">
                <a href="#">
                  <img
                    className="img-fluid"
                    src="/assets/images/brand-2.png"
                    alt="brand-logo"
                  />
                </a>
              </div>
            </div>
            <div className="col">
              <div className="brand-logo">
                <a href="#">
                  <img
                    className="img-fluid"
                    src="/assets/images/brand-3.png"
                    alt="brand-logo"
                  />
                </a>
              </div>
            </div>
            <div className="col">
              <div className="brand-logo">
                <a href="#">
                  <img
                    className="img-fluid"
                    src="/assets/images/brand-4.png"
                    alt="brand-logo"
                  />
                </a>
              </div>
            </div>
            <div className="col">
              <div className="brand-logo">
                <a href="#">
                  <img
                    className="img-fluid"
                    src="/assets/images/brand-5.png"
                    alt="brand-logo"
                  />
                </a>
              </div>
            </div>
            <div className="col">
              <div className="brand-logo">
                <a href="#">
                  <img
                    className="img-fluid"
                    src="/assets/images/brand-8.png"
                    alt="brand-logo"
                  />
                </a>
              </div>
            </div>
            <div className="col">
              <div className="brand-logo">
                <a href="#">
                  <img
                    className="img-fluid"
                    src="/assets/images/brand-7.png"
                    alt="brand-logo"
                  />
                </a>
              </div>
            </div>
            <div className="col">
              <div className="brand-logo">
                <a href="#">
                  <img
                    className="img-fluid"
                    src="/assets/images/brand-5.png"
                    alt="brand-logo"
                  />
                </a>
              </div>
            </div>
            <div className="col">
              <div className="brand-logo">
                <a href="#">
                  <img
                    className="img-fluid"
                    src="/assets/images/brand-8.png"
                    alt="brand-logo"
                  />
                </a>
              </div>
            </div>
            <div className="col">
              <div className="brand-logo">
                <a href="#">
                  <img
                    className="img-fluid"
                    src="/assets/images/brand-3.png"
                    alt="brand-logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsContent;
