/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import MobileHeader from "./_mobile-header";
import DesktopLogo from "./_desktop-logo";
import NavigationMenu from "./_navigation-menu";

const HeaderComponent = () => {
  return (
    <header id="header" className="header tra-menu navbar-light">
      <div className="header-wrapper">
        <MobileHeader />

        <div className="wsmainfull menu clearfix">
          <div className="wsmainwp clearfix">
            <DesktopLogo />
            <NavigationMenu />
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderComponent;
