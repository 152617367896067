const MainContent = () => {
  return (
    <section
      id="content-3"
      className="content-3 wide-60 content-section division"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-xl-8">
            <div className="section-title title-01 mb-70">
              <h2 className="h2-md">Security, Simplicity, Interoperability</h2>
              <p className="p-xl">
                Hassle free collections and disbursement through mutiple
                channels. Secure checkouts, payment data encryption, and
                PCI-compliant servers enable customers to make transactions
                confidently with zero fear.
              </p>
            </div>
          </div>
        </div>
        <div className="row mb-50">
          <div className="col">
            <div className="img-block text-center wow fadeInUp">
              <img
                className="img-fluid"
                src="/assets/images/world-map.png"
                alt="content-image"
              />
            </div>
          </div>
        </div>
        <div className="row d-flex align-items-center mt-100 mb-100">
          <div className="col-md-7 col-lg-6 order-last order-md-2">
            <div className="txt-block left-column wow fadeInRight">
              <div className="txt-box mb-20">
                <h5 className="h5-lg">Seamless and secure online payments</h5>
                <p className="p-lg">
                  Quaerat sodales sapien euismod blandit at vitae ipsum primis
                  undo and cubilia laoreet augue and luctus magna dolor luctus
                  at egestas sapien vitae nemo egestas volute and turpis dolores
                  aliquam quaerat sodales a sapien
                </p>
              </div>
              <div className="txt-box">
                <h5 className="h5-lg">24/7 Access to your card information</h5>
                <ul className="simple-list">
                  <li className="list-item">
                    <p className="p-lg">
                      Fringilla risus, luctus mauris auctor euismod an iaculis
                      luctus magna purus pretium ligula purus and quaerat
                    </p>
                  </li>
                  <li className="list-item">
                    <p className="p-lg">
                      Nemo ipsam egestas volute turpis dolores undo ultrice
                      aliquam quaerat at sodales sapien purus
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-lg-6 order-first order-md-2">
            <div className="rel img-block right-column wow fadeInLeft">
              <img
                className="img-fluid"
                src="/assets/images/banking-04.png"
                alt="content-image"
              />
            </div>
          </div>
        </div>
        <div className="top-row pb-50">
          <div className="row d-flex align-items-center">
            <div className="col-md-5 col-lg-6">
              <div className="img-block left-column wow fadeInRight">
                <img
                  className="img-fluid"
                  src="/assets/images/img-14.png"
                  alt="content-image"
                />
              </div>
            </div>
            <div className="col-md-7 col-lg-6">
              <div className="txt-block right-column wow fadeInLeft">
                <div className="txt-box mb-20">
                  <h5 className="h5-lg">All-in-One Marketing Solutions</h5>
                  <p className="p-lg">
                    Quaerat sodales sapien euismod blandit at vitae ipsum primis
                    undo and cubilia laoreet augue and luctus magna dolor luctus
                    at egestas sapien vitae nemo egestas volute and turpis
                    dolores aliquam quaerat sodales a sapien
                  </p>
                </div>
                <div className="txt-box">
                  <h5 className="h5-lg">Strategy and Analytics Consulting</h5>
                  <ul className="simple-list">
                    <li className="list-item">
                      <p className="p-lg">
                        Fringilla risus, luctus mauris auctor euismod an iaculis
                        luctus magna purus pretium ligula purus and quaerat
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="p-lg">
                        Nemo ipsam egestas volute turpis dolores undo ultrice
                        aliquam quaerat at sodales sapien purus
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-row">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 order-last order-lg-2">
              <div className="txt-block slim-column left-column wow fadeInRight">
                <span className="section-id txt-upcase">Totally Optimized</span>
                <h2 className="h2-xs">More productivity with less effort</h2>
                <p className="p-lg">
                  Quaerat sodales sapien euismod blandit purus a purus ipsum
                  primis in cubilia laoreet augue luctus magna dolor luctus and
                  egestas sapien egestas vitae nemo volute
                </p>
                <p className="p-lg">
                  Quaerat sodales sapien euismod blandit at vitae ipsum primis
                  undo and cubilia laoreet augue and luctus magna dolor luctus
                  at egestas sapien vitae nemo egestas volute and turpis dolores
                  aliquam quaerat sodales a sapien
                </p>
              </div>
            </div>
            <div className="col-lg-6 order-first order-lg-2">
              <div className="cb-wrapper">
                <div className="cb-holder wow fadeInLeft">
                  <div className="cb-single-box">
                    <p className="p-lg cb-header">New Customers</p>
                    <h2 className="h2-title-xs statistic-number">
                      <sup>+</sup>
                      <span className="count-element">784</span>
                    </h2>
                    <p className="p-md mt-5 ico-10">
                      <span className="green-color">
                        <span className="flaticon-"></span> 4.6%
                      </span>{" "}
                      vs last 7 days
                    </p>
                  </div>
                  <hr className="divider" />
                  <div className="cb-single-box">
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-md">
                          Fringilla risus luctus mauris auctor and purus euismod
                          purus
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-md">
                          Nemo ipsam volute turpis dolores ut quaerat sodales
                          sapien
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="cb-single-box cb-box-rounded bg-green white-color mt-25">
                    <h4 className="h4-lg">98.245</h4>
                    <p className="p-lg">Ligula risus auctor tempus</p>
                  </div>
                </div>
                <div className="cb-shape-1">
                  <img
                    className="img-fluid"
                    src="/assets/images/bg-shape-1.png"
                    alt="content-image"
                  />
                </div>
                <div className="cb-shape-2">
                  <img
                    className="img-fluid"
                    src="/assets/images/bg-shape-2.png"
                    alt="content-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainContent;
