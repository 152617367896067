import HeroSection from "./_hero-section";
import MainContent from "./_main-content";
import StatsContent from "./_stats-content";
import ClientsContent from "./_clients-content";
import FaqsContent from "./_faqs-content";
import CtaContent from "./_cta-content";

const HomeComponent = () => {
  return (
    <>
      <HeroSection />
      <MainContent />
      <StatsContent />
      <ClientsContent />
      <FaqsContent />
      <CtaContent />
    </>
  );
};

export default HomeComponent;
