/* eslint-disable jsx-a11y/anchor-is-valid */
const MobileHeader = () => {
  return (
    <div className="wsmobileheader clearfix">
      <span className="smllogo">
        <img src="/assets/images/regular-logo-dark.png" alt="mobile-logo" />
      </span>
      <a id="wsnavtoggle" className="wsanimated-arrow">
        <span />
      </a>
    </div>
  );
};

export default MobileHeader;
