import { Link } from "react-router-dom";

const NavigationMenu = () => {
  return (
    <nav className="wsmenu clearfix">
      <ul className="wsmenu-list nav-green-hover">
        <li className="nl-simple">
          <Link to="/">Home</Link>
        </li>
        <li className="nl-simple" aria-haspopup="true">
          <a href="#">Company</a>
        </li>
        <li className="nl-simple" aria-haspopup="true">
          <a href="#">Product</a>
        </li>
        <li className="nl-simple" aria-haspopup="true">
          <a href="#">Pricing</a>
        </li>
        <li aria-haspopup="true">
          <a href="#">
            Developers <span className="wsarrow" />
          </a>
          <ul className="sub-menu">
            <li aria-haspopup="true">
              <a href="#">Overview</a>
            </li>
            <li aria-haspopup="true">
              <a href="#">API Reference</a>
            </li>
          </ul>
        </li>
        <li className="nl-simple" aria-haspopup="true">
          <a href="#">Support</a>
        </li>
        <li className="nl-simple" aria-haspopup="true">
          <a
            href="javascript:void(0)"
            className="btn btn-green tra-white-hover last-link"
          >
            Get Started For Free
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default NavigationMenu;
