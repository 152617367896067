const FooterComponent = () => {
  return (
    <footer id="footer-4" className="footer division">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="footer-info mb-40">
              <img
                className="footer-logo"
                src="/assets/images/regular-logo-dark.png"
                alt="footer-logo"
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-2 col-lg-2 col-xl-2 offset-xl-1">
            <div className="footer-links mb-40">
              <h6 className="h6-xl">Company</h6>
              <ul className="foo-links text-secondary clearfix">
                <li>
                  <p className="p-md">
                    <a href="#">About Us</a>
                  </p>
                </li>
                <li>
                  <p className="p-md">
                    <a href="#">Careers</a>
                  </p>
                </li>
                <li>
                  <p className="p-md">
                    <a href="#">Press & Media</a>
                  </p>
                </li>
                <li>
                  <p className="p-md">
                    <a href="#">Advertising</a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-2">
            <div className="footer-links mb-40">
              <h6 className="h6-xl">Discover</h6>
              <ul className="foo-links text-secondary clearfix">
                <li>
                  <p className="p-md">
                    <a href="#">Our Blog</a>
                  </p>
                </li>
                <li>
                  <p className="p-md">
                    <a href="#">Plans & Pricing</a>
                  </p>
                </li>
                <li>
                  <p className="p-md">
                    <a href="#">Customers</a>
                  </p>
                </li>
                <li>
                  <p className="p-md">
                    <a href="#">API Reference</a>
                  </p>
                </li>
                <li>
                  <p className="p-md">
                    <a href="#">Cookie Policy</a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-2">
            <div className="footer-links mb-40">
              <h6 className="h6-xl">Support</h6>
              <ul className="foo-links text-secondary clearfix">
                <li>
                  <p className="p-md">
                    <a href="#">FAQs</a>
                  </p>
                </li>
                <li>
                  <p className="p-md">
                    <a href="#">Editor Help</a>
                  </p>
                </li>
                <li>
                  <p className="p-md">
                    <a href="#">Community</a>
                  </p>
                </li>
                <li>
                  <p className="p-md">
                    <a href="#">Sales</a>
                  </p>
                </li>
                <li>
                  <p className="p-md">
                    <a href="#">Contact Us</a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2">
            <div className="footer-links mb-40">
              <h6 className="h6-xl">Connect With Us</h6>
              <ul className="footer-socials text-secondary ico-25 text-center clearfix">
                <li>
                  <a href="#">
                    <span className="flaticon-facebook"></span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="flaticon-twitter"></span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="flaticon-github"></span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="flaticon-youtube"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="bottom-footer">
          <div className="row row-cols-1 row-cols-md-2 d-flex align-items-center">
            <div className="col">
              <div className="footer-copyright">
                <p>
                  &copy; {new Date().getFullYear()} CSWITCH. All Rights Reserved
                </p>
              </div>
            </div>
            <div className="col">
              <ul className="bottom-footer-list text-secondary text-end">
                <li className="first-li">
                  <p>
                    <a href="#">Privacy Policy</a>
                  </p>
                </li>
                <li className="last-li">
                  <p>
                    <a href="#">Terms & Conditions</a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
