import React from "react";
import { LayoutComponent } from "../layout";

function App() {
  return (
    <div className="App">
      <LayoutComponent />
    </div>
  );
}

export default App;
