import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <section id="hero-14" className="bg-fixed hero-section division">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-sm-9 col-md-7 col-lg-5">
            <div className="pt-60">
              <div className="hero-14-txt white-color wow fadeInRight">
                <div className="hero-logo-rounded bg-tra-white mb-40">
                  <span>Proudly African</span>
                </div>
                <h2 className="h2-xl fw-bolder">
                  Modern Infrastructure For Online Payments
                </h2>
                <p className="p-xl">
                  CSwitch deploys the latest technology to enable reliable and
                  secure online payment services.
                </p>
                <div className="btns-group mb-30 wow fadeInUp">
                  <a
                    href="javascript:void(0)"
                    className="btn btn-green tra-white-hover mr-25"
                  >
                    Get Started For Free
                  </a>
                  <a
                    href="javascript:void(0)"
                    className="btn btn-md btn-transparent ico-20 ico-right"
                  >
                    Contact Sales
                  </a>
                </div>
                <ul className="advantages clearfix wow fadeInUp fs-6">
                  <li className="first-li">
                    <Link to="/">Login to your dashboard</Link>
                  </li>
                  <li>
                    <Link to={"/"}>API Reference</Link>
                  </li>
                  <li className="last-li">
                    <Link to={"/"}>Why CSWITCH</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
