const FaqsContent = () => {
  return (
    <section id="faqs-2" className="wide-60 faqs-section division">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-xl-8">
            <div className="section-title title-01 mb-80">
              <h2 className="h2-md">Got Questions? Look Here</h2>
              <p className="p-xl">
                Aliquam a augue suscipit, luctus neque purus ipsum neque dolor
                primis a libero tempus, blandit and cursus varius and magnis
                sapien
              </p>
            </div>
          </div>
        </div>
        <div className="faqs-2-questions">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col">
              <div className="questions-holder pr-15">
                <div className="question wow fadeInUp">
                  <h5 className="h5-md">
                    Can I see CSWITCH in action before purchasing?
                  </h5>
                  <p className="p-lg">
                    Etiam amet mauris suscipit in odio integer congue metus
                    vitae arcu mollis blandit ultrice ligula egestas and magna
                    suscipit lectus magna suscipit luctus blandit vitae
                  </p>
                </div>
                <div className="question wow fadeInUp">
                  <h5 className="h5-md">
                    What are the requirements for using CSWITCH?
                  </h5>
                  <p className="p-lg">
                    An enim nullam tempor sapien gravida a donec ipsum enim an
                    porta justo integer at velna vitae auctor integer congue
                    undo magna at pretium purus pretium
                  </p>
                </div>
                <div className="question wow fadeInUp">
                  <h5 className="h5-md">
                    Can I use CSWITCH on different devices?
                  </h5>
                  <ul className="simple-list">
                    <li className="list-item">
                      <p className="p-lg">
                        Fringilla risus, luctus mauris orci auctor purus ligula
                        euismod pretium purus pretium rutrum tempor sapien
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="p-lg">
                        Nemo ipsam egestas volute turpis dolores ut aliquam
                        quaerat sodales sapien undo pretium a purus
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="questions-holder pl-15">
                <div className="question wow fadeInUp">
                  <h5 className="h5-md">Do you have a free trial?</h5>
                  <p className="p-lg">
                    Cubilia laoreet augue egestas and luctus donec curabite diam
                    vitae dapibus libero and quisque gravida donec and neque.
                    Blandit justo aliquam molestie nunc sapien
                  </p>
                </div>
                <div className="question wow fadeInUp">
                  <h5 className="h5-md">How does CSWITCH handle my privacy?</h5>
                  <p className="p-lg">
                    Etiam amet mauris suscipit sit amet in odio. Integer congue
                    leo metus. Vitae arcu mollis blandit ultrice ligula
                  </p>
                  <p className="p-lg">
                    An enim nullam tempor sapien gravida donec congue leo metus.
                    Vitae arcu mollis blandit integer at velna
                  </p>
                </div>
                <div className="question wow fadeInUp">
                  <h5 className="h5-md">I have an issue with my account</h5>
                  <ul className="simple-list">
                    <li className="list-item">
                      <p className="p-lg">
                        Fringilla risus, luctus mauris orci auctor purus
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="p-lg">
                        Quaerat sodales sapien euismod blandit purus and ipsum
                        primis in cubilia laoreet augue luctus
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="more-questions">
              <h5 className="h5-sm">
                Have more questions?{" "}
                <a href="javascript:void(0)">Contact Sales</a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqsContent;
