const CtaContent = () => {
  return (
    <section id="cta-3" className="cta-section division">
      <div className="cta-3-holder">
        <div className="container">
          <div className="bg-white cta-3-wrapper">
            <div className="row d-flex align-items-center">
              <div className="col-lg-7 col-lg-8">
                <div className="cta-3-txt">
                  <h4 className="h4-xl">
                    Try CSWITCH now. Start collecting payments almost instantly.
                  </h4>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="text-end">
                  <div className="cta-3-btn text-center">
                    <a
                      href="javascript:void(0)"
                      className="btn btn-green tra-grey-hover"
                    >
                      Create a Free Account
                    </a>
                    <p>
                      <a href="#">Pricing Details</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaContent;
