const StatsContent = () => {
  return (
    <section id="statistic-3" className="bg-03 statistic-section division">
      <div className="container white-color">
        <div className="statistic-1-wrapper">
          <div className="row justify-content-md-center row-cols-1 row-cols-md-3 pb-50">
            <div id="sb-1-1" className="col">
              <div className="statistic-block wow fadeInUp">
                <h2 className="h2-xl statistic-number">
                  <span className="count-element">28</span>%
                </h2>
                <h5 className="h5-lg">Faster Access</h5>
                <p className="p-lg">
                  Enim nullam tempor at sapien gravida donec a blandit integer
                  posuere porta justo velna
                </p>
              </div>
            </div>
            <div id="sb-1-2" className="col">
              <div className="statistic-block wow fadeInUp">
                <h2 className="h2-xl statistic-number">
                  <span className="count-element">54</span>%
                </h2>
                <h5 className="h5-lg">Productivity</h5>
                <p className="p-lg">
                  Enim nullam tempor at sapien gravida donec a blandit integer
                  posuere porta justo velna
                </p>
              </div>
            </div>
            <div id="sb-1-3" className="col">
              <div className="statistic-block wow fadeInUp">
                <h2 className="h2-xl statistic-number">
                  <span className="count-element">36</span>%
                </h2>
                <h5 className="h5-lg">Secure Access</h5>
                <p className="p-lg">
                  Enim nullam tempor at sapien gravida donec a blandit integer
                  posuere porta justo velna
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatsContent;
